$body-bg: #333;
$body-color: #eee;
$primary: #229A8D;
$primary-light: lighten($primary, 10%);
$body-bg-light: lighten($body-bg, 5%);
$dropdown-link-hover-bg: $primary;
// $navbar-dark-brand-color: #229A8D;
// $navbar-dark-brand-hover-color: scale-color($navbar-dark-brand-color, $lightness: -15%);

@import "~bootstrap/scss/bootstrap";
