.UpdatesComponent .accordion-item {
  border-color: #e9f5f4;
  text-align: left;
}

.UpdatesComponent h5 {
  text-align: left;
}

.UpdatesComponent {
  margin-bottom: 40px;
}
