.text-element, .html-element, .list-element, .quote-element {
  text-align: left;
}

.image-element {
  margin: 5px 5px 15px 5px;
  border-radius: 15px;
}

/* .img-fluid.img-element {
  max-width: 80%;
} */

.quote-element {
  padding-bottom: 20px;
}

.quote-element p:before {
  content: "“";
  font-size: 25px;
}

.quote-element p:after {
  content: "”";
  font-size: 25px;
}

.quote-element p {
  margin-bottom: 0;
}

.quote-element span {
  margin-left: 35px;
  color: #999;
}

.link-element {
  padding-bottom: 10px;
}

.center {
  text-align: center;
}
