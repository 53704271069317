@import "../../custom.scss";

.BlogEntry:last-child {
  border: unset;
  cursor: pointer;
}
.BlogEntry {
  border-bottom: 2px solid $primary;
  padding: 10px 10px 20px 10px;
}
.BlogEntry:hover {
  background-color: $body-bg-light;
}

.BlogDate {
  font-weight: bold;
}

.BlogPreview, .BlogTitle {
  text-align: left;
}