.App {
  text-align: center;
  margin-bottom: 75px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-brand {
  background-color: #196d63;
}

.App {
  padding-top: 20px;
  /* background-color: #303030; */
}

li {
  list-style: none;
}

li:before {
  content: '\1F5D1';
  margin-left: -20px;
  margin-right: 10px;
}
