.Item {
  transition: all .1s ease-in-out;
}

.Item:hover {
  transform: scale(1.06);
}

.card {
  margin-bottom: 20px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
